import React, { useEffect, useState, forwardRef } from 'react'
import { Bar, Radar } from "react-chartjs-2";

import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,

    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,

} from "chart.js";

ChartJS.register(
    BarElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
    ChartDataLabels,
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
);

import toast from 'react-hot-toast'

// ** Reactstrap Imports
import {
    Row,
    Col,
    Card,
    Input,
    Label,
    Button,
    CardTitle,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Toast,
    Spinner,
    Badge,
    UncontrolledButtonDropdown
} from 'reactstrap'

import DatePicker from "react-datepicker";
// import DatePicker, { DateObject } from "react-multi-date-picker";
// import Footer from "react-multi-date-picker/plugins/range_picker_footer";

import { AiOutlineCalendar } from "react-icons/ai";
//Redux-Service
import { selectLanguageUse } from '../../../redux/slices/language.Slice'
import { useSelector } from 'react-redux';

import HorizontalStackedBarChartLegends from '../CustomChart/HorizontalStackedBarChartLegends';
import Selection from '../Selection/Selection';
import states from '../../../utility/State'
import { getAllIndustryListService } from '../../../services/industryService';
import { getToken } from '../../../utility/AuthService';
import classNames from 'classnames';
import moment from 'moment';
import CustomDatePicker from '../CustomDatePicker/CustomDatePicker';
import { filterMaturityIndustryAndStateService, getAdvanceMaturityDataService } from '../../../services/analyticsServices/advacneAnalyticsService/maturityLevelService';
import { demographicsListService } from '../../../services/demographicsService';
import { useForm } from 'react-hook-form';

const ESGMaturityLevels = () => {
    const token = getToken()
    const [isLoading, setLoading] = useState(false)
    const [countryName, setCountryName] = useState()
    const [stateName, setStateName] = useState()
    const [industryName, setIndustryName] = useState()
    const [allIndustryList, setAllIndustryList] = useState()
    const [parcentOrDigit, setParcentOrDigit] = useState('digit')
    // const [startDate, setStartDate] = useState(new Date());
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [chartData, setchartData] = useState()
    const [advanceData, setadvanceData] = useState()
    const [LeaderData, setLeaderData] = useState()
    const [LaggardData, setLaggardData] = useState()
    const [IntermediateData, setIntermediateData] = useState()
    const [BeginnerData, setBeginnerData] = useState()
    const [charLabel, setCharLabel] = useState()
    const [advanceToParData, setadvanceToParData] = useState()
    const [LeaderToParData, setLeaderToParData] = useState()
    const [LaggardToParData, setLaggardToParData] = useState()
    const [IntermediateToParData, setIntermediateToParData] = useState()
    const [BeginnerToParData, setBeginnerToParData] = useState()
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const languageUse = useSelector(selectLanguageUse || user?.language)

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        setValue
    } = useForm();

    const watchCountry = watch("Country")
    const watchState = watch("State")

    /**
        * @function getCountryList
        * @param
        * @description get country list using api
    */
    const getCountryList = async () => {
        try {
            const result = await demographicsListService({
                type: 'Country',
                typeId: ""
            });
            if (result?.data?.statusCode === 200) {
                setCountryList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setCountryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    /**
        * @function getStateList
        * @param
        * @description get state list using api
    */
    const getStateList = async () => {
        try {
            const result = await demographicsListService({
                type: 'State',
                typeId: watchCountry?.value
            });
            if (result?.data?.statusCode === 200) {
                setStateList(result?.data?.data?.list?.map(item => {
                    return {
                        value: item?._id,
                        label: item?.name
                    }
                }))
            }
        } catch (error) {
            setStateList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    };

    useEffect(() => {
        getCountryList()
    }, []);

    useEffect(() => {
        setStateName()
        setValue("State", null)
        if (watchCountry?.value) {
            getStateList()
        }
    }, [watchCountry]);

    /**
    * @function fetchIndustryData
    * @param ()
    * @description to get all indistry list
    */
    const fetchIndustryData = async () => {
        try {
            const result = await getAllIndustryListService({
                token,
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                if (result?.data?.data?.list?.length > 0) {
                    setAllIndustryList(result?.data?.data?.list?.map((item) => {
                        return {
                            value: item?._id,
                            label: languageUse === 'English' ? item?.name : item?.name_Malay
                        }
                    }))
                }
            } else {
                setLoading(false)
                setAllIndustryList([])
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }

        } catch (error) {
            setLoading(false)
            setAllIndustryList([])
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    /**
* @function getAdvanceMaturityData
* @param ()
* @description to get chart data
*/
    const getAdvanceMaturityData = async () => {
        try {
            const result = await getAdvanceMaturityDataService({
                token
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setchartData(result?.data?.data)
                setCharLabel(Object.keys(result?.data?.data?.dimensionLevelCounts))
                setadvanceData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Advanced))
                setLeaderData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Leader))
                setLaggardData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Laggard))
                setBeginnerData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Beginner))
                setIntermediateData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Intermediate))

                setadvanceToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Advanced))
                setLeaderToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Leader))
                setLaggardToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Laggard))
                setIntermediateToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Intermediate))
                setBeginnerToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Beginner))
            } else {
                setLoading(false)
                setchartData()
                setCharLabel()
                setadvanceData()
                setLeaderData()
                setLaggardData()
                setBeginnerData()
                setIntermediateData()
                setadvanceToParData()
                setLeaderToParData()
                setLaggardToParData()
                setIntermediateToParData()
                setBeginnerToParData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setCharLabel()
            setadvanceData()
            setLeaderData()
            setLaggardData()
            setBeginnerData()
            setIntermediateData()
            setadvanceToParData()
            setLeaderToParData()
            setLaggardToParData()
            setIntermediateToParData()
            setBeginnerToParData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }

    }

    useEffect(() => {
        fetchIndustryData()
        // getAdvanceMaturityData()
    }, [languageUse]);

    /**
* @function filterStateAndIndustryMaturityData
* @param ()
* @description to get filter data
*/
    const filterStateAndIndustryMaturityData = async () => {
        try {
            const result = await filterMaturityIndustryAndStateService({
                token,
                country: watchCountry?.label || '',
                state: watchState?.label || '',
                industry: industryName?.value || '',
                fromDate: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
                toDate: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
            });
            if (result?.data?.statusCode === 200) {
                setLoading(false)
                setchartData(result?.data?.data)
                setCharLabel(Object.keys(result?.data?.data?.dimensionLevelCounts))
                setadvanceData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Advanced))
                setLeaderData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Leader))
                setLaggardData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Laggard))
                setBeginnerData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Beginner))
                setIntermediateData(Object.values(result?.data?.data?.dimensionLevelCounts)?.map(item => item?.Intermediate))

                setadvanceToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Advanced))
                setLeaderToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Leader))
                setLaggardToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Laggard))
                setIntermediateToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Intermediate))
                setBeginnerToParData(Object.values(result?.data?.data?.dimensionLevelpercentages)?.map(item => item?.Beginner))
            } else {
                setLoading(false)
                setchartData()
                setCharLabel()
                setadvanceData()
                setLeaderData()
                setLaggardData()
                setBeginnerData()
                setIntermediateData()
                setadvanceToParData()
                setLeaderToParData()
                setLaggardToParData()
                setIntermediateToParData()
                setBeginnerToParData()
                toast.error(result?.response?.data?.message || 'Something went wrong')
            }
        } catch (error) {
            setLoading(false)
            setchartData()
            setCharLabel()
            setadvanceData()
            setLeaderData()
            setLaggardData()
            setBeginnerData()
            setIntermediateData()
            setadvanceToParData()
            setLeaderToParData()
            setLaggardToParData()
            setIntermediateToParData()
            setBeginnerToParData()
            toast.error(error?.response?.data?.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (watchCountry || watchState || industryName || startDate || endDate) {
            filterStateAndIndustryMaturityData()
        } else {
            getAdvanceMaturityData()
        }
    }, [watchCountry, watchState, industryName, startDate, endDate]);

    /**
   * @function handleDigit
   * @param ()
   * @description to show data in digit
   */
    const handleDigit = async () => {
        setParcentOrDigit('digit')
    };

    /**
   * @function handleParcent
   * @param ()
   * @description to show data in parcent
   */
    const handleParcent = async () => {
        setParcentOrDigit('parcent')
    };

    const horizontalStackData = {
        labels: charLabel,
        datasets: [
            {
                data: parcentOrDigit === 'digit' ? LeaderData : LeaderToParData,
                backgroundColor: "#38761D",
                hoverBackgroundColor: "#38761D",
                label: '',
            }, {
                data: parcentOrDigit === 'digit' ? advanceData : advanceToParData,
                backgroundColor: "#93C47D",
                hoverBackgroundColor: "#93C47D",
                label: '',
            }, {
                data: parcentOrDigit === 'digit' ? IntermediateData : IntermediateToParData,
                backgroundColor: "#B6D7A8",
                hoverBackgroundColor: "#B6D7A8",
                label: '',
            }, {
                data: parcentOrDigit === 'digit' ? BeginnerData : BeginnerToParData,
                backgroundColor: "#FF9900",
                hoverBackgroundColor: "#FF9900",
                label: '',
            }, {
                data: parcentOrDigit === 'digit' ? LaggardData : LaggardToParData,
                backgroundColor: "#FF0000",
                hoverBackgroundColor: "#FF0000",
                label: '',
            }
        ]
    };

    const horizontalStackOptions = {
        responsive: true,
        indexAxis: 'y',

        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            xAxes: [
                {
                    scaleLabel: {
                        display: false
                    },
                    gridLines: {
                        display: false

                    },
                    stacked: true
                }
            ],
            yAxes: [
                {
                    gridLines: {
                        display: false,
                    },
                    stacked: true
                }
            ]
        },

        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                color: "#000",
                formatter: function (value, context) {
                    return parcentOrDigit === 'digit' ? value : `${value}%`;
                }
            },
            tooltip: {
                callbacks: {
                    title: (tooltipItem, data) => {
                        if (tooltipItem[0]?.datasetIndex === 0) {
                            return 'Leader'
                        }
                        if (tooltipItem[0]?.datasetIndex === 1) {
                            return 'Advanced'
                        }
                        if (tooltipItem[0]?.datasetIndex === 2) {
                            return 'Intermediate'
                        }
                        if (tooltipItem[0]?.datasetIndex === 3) {
                            return 'Beginner'
                        }
                        if (tooltipItem[0]?.datasetIndex === 4) {
                            return 'Laggard'
                        }

                    }
                }
            }
        },
        pointLabelFontFamily: "Quadon Extra Bold",
        scaleFontFamily: "Quadon Extra Bold",
    };

    /**
    * @function onChange
    * @param ()
    * @description to set chagne date
    */
    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    /**
    * @function clearSelectedDate
    * @param ()
    * @description to clear selected date
    */
    const clearSelectedDate = () => {
        setStartDate(null);
        setEndDate(null);
    }

    const DateCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='bg-btn-analytics p-1 rounded-2'>
            <AiOutlineCalendar role='button' size={20} className="text-primary" onClick={onClick} ref={ref} />
            <button className={classNames("bg-btn-analytics text-primary p-0", { "p-1": value })} onClick={onClick} ref={ref}>
                {value}
            </button>

        </div>

    ));

    const FooterCustomInput = ({ date, value, onChange }) => (
        <div className='d-flex justify-content-between'>

            <div className='py-2'>
                {startDate && <span className='p-2 border rounded-2'>{moment(startDate).format("MMM DD, yyyy")}</span>} - {endDate && <span className='p-2 border rounded-2'>{moment(endDate).format("MMM DD, yyyy")}</span>}
            </div>
            <div className='d-flex'>
                <Button className='me-1' color="danger" onClick={() => clearSelectedDate()}>Clear</Button>
                <Button className='me-1' outline color="primary">Cancel</Button>
                <Button className='' color="primary">Apply</Button>
            </div>
        </div>
    );

    return (
        <>
            <Card className='border-0'>
                <div className=''>
                    <h4>ESG Maturity Levels:</h4>
                    <p>Number of Vendors Corresponding to Each Maturity Level</p>
                </div>

                <div className='p-2'>
                    <div className='d-flex flex-md-wrap flex-column flex-md-row justify-content-between align-items-center'>
                        <div className='me-2'>
                            <Selection className={'w-100'} options={countryList} control={control} title={'Country'} name={'Country'} setWatchValue={setCountryName} styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '250px'
                                }),
                            }} />
                        </div>
                        {watchCountry?.value &&
                            <div className='me-2'>
                                <Selection className={'w-100'} options={stateList} control={control} title={'State'} name={'State'} setWatchValue={setStateName} styles={{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '250px'
                                    }),
                                }} />
                            </div>
                        }
                        <div className=''>
                            <Selection className={'w-100'} options={allIndustryList} title={'Industry'} name={'Industry'} setWatchValue={setIndustryName} styles={{
                                control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '250px'
                                }),
                            }} />
                        </div>
                        {/* <div className='text-end d-flex align-items-start rounded-pill bg-btn-analytics px-1'>
                            <Button className={parcentOrDigit === 'parcent' ? "float-start analyticsConvertButtonClicked login-btn-min-width mb-4" : "float-start analyticsConvertButton login-btn-min-width mb-4"} variant='outline-primary' onClick={handleParcent}>
                                {isLoading ? <Spinner variant='dark' size='sm' /> : 'Percentage'}
                            </Button>
                          
                            <Button className={parcentOrDigit === 'digit' ? "float-start analyticsConvertButtonClicked login-btn-min-width mb-4" : "float-start analyticsConvertButton login-btn-min-width mb-4"} variant='outline-primary' onClick={handleDigit}>
                                {isLoading ? <Spinner variant='dark' size='sm' /> : 'Digit number'}
                            </Button>
                        </div> */}
                        <div className='d-flex flex-column flex-md-row align-items-center'>
                            <div className='me-2 mb-2 mb-md-0'>
                                {/* <DatePicker
                                    selected={startDate}
                                    onChange={(date)=>onChange(date)}
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectsRange
                                    monthsShown={2}
                                    withPortal
                                    showTimeInput
                                    customInput={<DateCustomInput />}
                                    customTimeInput={<FooterCustomInput />}
                                /> */}
                                <CustomDatePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                            </div>

                            <div className='d-flex align-items-center rounded-pill bg-btn-analytics px-2 py-2'>
                                <Button color='' className={parcentOrDigit === 'parcent' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleParcent}>
                                    Percentage
                                </Button>
                                <Button color='' className={parcentOrDigit === 'digit' ? 'bg-white rounded-pill text-primary' : 'bg-btn-analytics rounded-pill text-primary'} onClick={handleDigit}>
                                    Digit number
                                </Button>
                            </div>

                        </div>

                    </div>

                </div>

                <div className='border rounded-2 p-3'>
                    <HorizontalStackedBarChartLegends />

                    <div className='mb-3 p-1'>
                        <Bar data={horizontalStackData} options={horizontalStackOptions}></Bar>
                    </div>
                </div>

            </Card>

        </>
    )
};

export default ESGMaturityLevels